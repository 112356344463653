import { useUserStore } from '@/store/user';

/**
 * Auth middleware.
 * Check the autentication
 */
export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const { $service } = useNuxtApp();
  const user = useUserStore();
  const res = await $service.auth.load();

  if (res !== 200) {
    if (!user.info?.id) {
      return navigateTo('/sign_in');
    }
  }
});
